import * as React from "react"
import Header from "../components/header"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFacebookF, faTwitter, faLinkedinIn, faInstagram, faRaspberryPi, } from '@fortawesome/free-brands-svg-icons'
import { faUser, faEnvelope } from '@fortawesome/free-regular-svg-icons'
import { Link, Element, Events, animateScroll as scroll, scrollSpy, scroller } from 'react-scroll'
import "./index.scss"
import SkillBar from "../components/skillbar"
import { faCar, faChessBoard, faCode, faDatabase, faDiceD6, faFilm, faMapMarked, faMapMarker, faMapMarkerAlt, faMicroscope, faMobileAlt, faNetworkWired, faPhone, faPhoneAlt, faPhoneVolume, faSpinner, faTools, faTv, } from "@fortawesome/free-solid-svg-icons"
import netcore from "../images/technologies/dotnet-core.svg";
import typescript from "../images/technologies/typescript.svg";
import react from "../images/technologies/react.svg";
import raspberrypi from "../images/technologies/raspberry-pi.svg";
// import { Tooltip } from "bootstrap";
import TechnologiesSlick from "../components/technologiesSlick/technologiesSlick"
import Obfuscate from 'react-obfuscate';
import { Link as i18Link, Trans, } from 'gatsby-plugin-react-i18next';
import MouseScroll from "../components/mouseScroll"
import SectionHeader from "../components/sectionHeader"
import AOS from 'aos';
import { isMobile } from "react-device-detect"
import "aos/dist/aos.css";
import TechnologiesSection from "../components/index/TechnologiesSection"
import { ContactForm, HomepageClient } from "../api/ApiClient"
import { AboutMe } from "../components/index/about-me/AboutMe"
import Services from "../components/index/services/Services"
import ResumeDownload from "../components/index/resume/ResumeDownload"
import SocialMedia from "../components/index/social-media/SocialMedia"
import SEO from "../components/seo/seo"
import { graphql } from "gatsby"

class IndexPage extends React.Component<any, any> {

  iconRefs = [React.createRef<any>(), React.createRef<any>(), React.createRef<any>(), React.createRef<any>()]

  apiClient: HomepageClient = new HomepageClient("https://api.bartoszlisiecki.com")

  constructor(props) {
    super(props);
    this.state = {
      contactForm: {
        sending: false,
        sended: false,
        name: "",
        email: "",
        text: ""
      }
    }
  }

  componentDidMount() {
    AOS.init({
      duration: isMobile ? 500 : 1000
    });
  }

  setFormValue = (e: React.ChangeEvent<any>) => {
    let form = { ...this.state.contactForm };
    this.setState({
      contactForm: {
        ...this.state.contactForm,
        [e.target.name]: e.target.value
      }
    })
    console.log(this.state);
  }

  sendForm = async () => {
    if (this.state.contactForm.sending || (this.state.contactForm.email == '' && this.state.contactForm.text == '' && this.state.contactForm.name == '')) {
      return;
    }

    this.setState({
      contactForm: {
        ...this.state.contactForm,
        sending: true
      }
    });

    const response = await this.apiClient.postForm(new ContactForm({
      email: this.state.contactForm.email,
      message: this.state.contactForm.text,
      name: this.state.contactForm.name
    }));

    this.setState({
      contactForm: { email: "", text: "", name: "", sending: false, sended: true }
    });
  }

  render() {
    return (
      <>
        <SEO />
        <Header />
        <Element name="start">
          <div className="section heroUnit" >
            <div className="dataContainer">
              <div className="blur"></div>
              <div>
                <div className="image"></div>
                <div className="name">BARTOSZ LISIECKI</div>
                <div className="profession"><Trans>A creative Full Stack Developer and Freelancer</Trans></div>
                <div className="socialMedia">
                  <a className="socialMediaLink" href="https://www.facebook.com/bartosz.lisiecki" title="Facebook">
                    <FontAwesomeIcon icon={faFacebookF} />
                  </a>
                  <a className="socialMediaLink" href="https://www.linkedin.com/in/bartosz-lisiecki/" title="LinkedIn">
                    <FontAwesomeIcon icon={faLinkedinIn} />
                  </a>
                  <a className="socialMediaLink" href="https://twitter.com/BartoszLisiecki" title="Twitter">
                    <FontAwesomeIcon icon={faTwitter} />
                  </a>
                  <a className="socialMediaLink" href="https://www.instagram.com/bartosz_lisiecki/" title="Instagram">
                    <FontAwesomeIcon icon={faInstagram} />
                  </a>
                </div>
              </div>
            </div>
          </div>
        </Element>
        <Element name="about">
          <AboutMe />
        </Element>
        <Element name="resume">
          <ResumeDownload />
        </Element>
        <Element name="services">
          <Services />
        </Element>
        <Element name="social-media-section">
          <SocialMedia />
        </Element>
        <Element name="contact">
          <div className="section contact" id="contacts">
            <div className="sectionContainer container-lg">
              <SectionHeader data-aos={"fade-right"} title="LETS TALK" subtitle="LET'S DO SOMETHING NEW, DIFFERENT, IMPORTANT" side="left" />
              <div className="sectionContent">
                <div className="row">
                  <div className="col-md-5" data-aos={"fade-right"}>
                    <div className="row">
                      <div className="col-12 d-flex mb-4">
                        <div className="contact-icon">
                          <FontAwesomeIcon icon={faPhoneVolume} />
                        </div>
                        <div>
                          <div className="contact-text"><Trans>Call me</Trans></div>
                          <div className="contact-value"><Obfuscate tel="+48 603 207 983" /></div>
                        </div>
                      </div>
                      <div className="col-12 d-flex mb-4">
                        <div className="contact-icon">
                          <FontAwesomeIcon icon={faEnvelope} />
                        </div>
                        <div>
                          <div className="contact-text">Email</div>
                          <div className="contact-value"><Obfuscate email="contact@bartoszlisiecki.com" /></div>
                        </div>
                      </div>
                      <div className="col-12 d-flex mb-4">
                        <div className="contact-icon">
                          <FontAwesomeIcon icon={faMapMarkerAlt} />
                        </div>
                        <div>
                          <div className="contact-text"><Trans>Address</Trans></div>
                          <div className="contact-value">ul. Rudzicka 54 <br />62-510 Konin</div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-7" data-aos={"fade-up"}>
                    {this.state.contactForm.sended && <div className="messageSended">
                      <Trans>Your message has been sent. Thank you!</Trans>
                    </div>}
                    <div className="row">
                      <div className="col-lg-6">
                        <div className="form-group">
                          <label htmlFor="name"><Trans>Name</Trans>:</label>
                          <input value={this.state.contactForm.name} onChange={(e) => this.setFormValue(e)} name="name" type="text" className="form-control" id="name" />
                        </div>
                      </div>
                      <div className="col-lg-6">
                        <div className="form-group">
                          <label htmlFor="email"><Trans>Phone / email</Trans>:</label>
                          <input value={this.state.contactForm.email} onChange={(e) => this.setFormValue(e)} name="email" type="text" className="form-control" id="contact" />
                        </div>
                      </div>
                      <div className="col-12">
                        <div className="form-group">
                          <label htmlFor="text"><Trans>Message</Trans>:</label>
                          <textarea value={this.state.contactForm.text} onChange={(e) => this.setFormValue(e)} name="text" rows={5} className="form-control" id="message"></textarea>
                        </div>
                      </div>
                      <div className="col-12 mt-3">
                        <button className="btn btn-primary" onClick={() => this.sendForm()}>
                          {this.state.contactForm.sending && <><FontAwesomeIcon spin={true} icon={faSpinner} /> </>}<Trans>Send</Trans></button>
                      </div>
                    </div>
                    <div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Element>
        <Element name="footer">
          <div className="section footer" id="fotoer">
            <div className="container-lg sectionContainer pb-4 pt-4">
              <div className="row">
                <div className="col-md order-2 order-md-1 d-flex  align-items-center justify-content-center justify-content-md-start">&copy; Bartosz Lisiecki</div>
                <div className="col-md order-1 order-md-2">
                  <div className="socialMedia mt-md-0">
                    <a className="socialMediaLink" href="https://www.facebook.com/bartosz.lisiecki" title="Facebook">
                      <FontAwesomeIcon icon={faFacebookF} />
                    </a>
                    <a className="socialMediaLink" href="https://www.linkedin.com/in/bartosz-lisiecki/" title="LinkedIn">
                      <FontAwesomeIcon icon={faLinkedinIn} />
                    </a>
                    <a className="socialMediaLink" href="https://twitter.com/BartoszLisiecki" title="Twitter">
                      <FontAwesomeIcon icon={faTwitter} />
                    </a>
                    <a className="socialMediaLink" href="https://www.instagram.com/bartosz_lisiecki/" title="Instagram">
                      <FontAwesomeIcon icon={faInstagram} />
                    </a>
                  </div>
                </div>
                <div className="col-md order-3 order-md-3"></div>
              </div>
            </div>
          </div>
        </Element>
      </>
    )
  }
}

export default IndexPage;


export const query = graphql`
  query($language: String!) {
    locales: allLocale(filter: {language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;