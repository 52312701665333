import { graphql } from "gatsby";
import React from "react";
import { Helmet } from "react-helmet";
import { Link as i18Link, Trans, useTranslation, withTranslation } from 'gatsby-plugin-react-i18next';

export class SEO extends React.Component<any, any> {
    render() {
        const { t, i18n } = this.props;

        return (
            <Helmet>
                <title>bartoszlisiecki.com | {t("Custom Software Development")}</title>
                <meta name="description" content={t("Software developmnet that is unique, scalable, and efficient. I use innovative solutions that will accelerate the technological development of your company.")} />
                <meta name="keywords" content={t("keywords")} />
                {i18n.languages.map(lang => {
                    return <link rel="alternate" hrefLang={lang} href={`https://bartoszlisiecki.com/${lang}/`} />
                })}

            </Helmet>
        )
    }
}

export default withTranslation()(SEO);


export const query = graphql`
  query($language: String!) {
    locales: allLocale(filter: {language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;