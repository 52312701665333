import { faCar, faChessBoard, faDiceD6, faEnvelope, faFilm, faMicroscope, faPhoneAlt, faUser } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import Obfuscate from 'react-obfuscate';
import aboutme from "../../../images/aboutme.svg";
import { Link as i18Link, Trans, } from 'gatsby-plugin-react-i18next';

import "./AboutMe.scss";

export class AboutMe extends React.Component {
    render() {
        return (<div className="section aboutme " id="aboutme" data-aos={"fade-up"}>
            <div className="sectionContainer container-lg">
                <div className="sectionContent">
                    <div className="row">
                        <div className="col-lg mb-4"><img className="aboutMeImage" src={aboutme} /></div>
                        <div className="col-lg mb-4" >
                            <div style={{ color: "#1cb698" }}>INTRO</div>
                            <h2 className="mb-4" style={{ fontWeight: "bold" }}><Trans>About me</Trans></h2>
                            <div>
                                <Trans>
                                    AboutMeText
                                </Trans>
                            </div>
                            {/* zmienić na repson  */}
                            <div className="mt-4">
                                <div className="row mt-2">
                                    <div className="col-12 d-flex align-items-center">
                                        <div className="contact-icon">
                                            <FontAwesomeIcon icon={faUser} />
                                        </div>
                                        <div>
                                            <div className="contact-text"><Trans>Name</Trans></div>
                                            <div className="contact-value">Bartosz Lisiecki</div>
                                        </div>
                                    </div>
                                    {/* <div className="col-sm-4 d-flex justify-content-center justify-content-sm-start mb-2"><FontAwesomeIcon className="me-3" icon={faUser} fixedWidth={true} style={{ color: "#1cb698", fontSize: "1.5rem" }} /> Name</div>
                                    <div className="col-sm-8 d-flex justify-content-center justify-content-sm-start mb-2">Bartosz Lisiecki</div> */}
                                </div>
                                <div className="row mt-2">
                                    <div className="col-12 d-flex align-items-center">
                                        <div className="contact-icon">
                                            <FontAwesomeIcon icon={faPhoneAlt} />
                                        </div>
                                        <div>
                                            <div className="contact-text"><Trans>Phone</Trans></div>
                                            <div className="contact-value">+48 603 207 983</div>
                                        </div>
                                    </div>

                                </div>
                                <div className="row mt-2">
                                    <div className="col-12 d-flex align-items-center">
                                        <div className="contact-icon">
                                            <FontAwesomeIcon icon={faEnvelope} />
                                        </div>
                                        <div>
                                            <div className="contact-text">Email</div>
                                            <div className="contact-value"><Obfuscate email="contact@bartoszlisiecki.com" /></div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                            <div className="mt-4">
                                {/* wyrównać na respo */}
                                <h4><Trans>My hobby</Trans></h4>
                                <div>
                                    <div className="distinction d-inline-flex mb-3 align-items-center"><FontAwesomeIcon icon={faMicroscope} fixedWidth={true} style={{ color: "#1cb698", fontSize: "1.5rem" }} className="me-1" /><Trans>Electronic</Trans></div>
                                    <div className="distinction d-inline-flex mb-3 align-items-center"><FontAwesomeIcon icon={faCar} fixedWidth={true} style={{ color: "#1cb698", fontSize: "1.5rem" }} className="me-1" /><Trans>Motosports</Trans></div>
                                    <div className="distinction d-inline-flex mb-3 align-items-center"><FontAwesomeIcon icon={faFilm} fixedWidth={true} style={{ color: "#1cb698", fontSize: "1.5rem" }} className="me-1" /><Trans>Movies</Trans></div>
                                    <div className="distinction d-inline-flex mb-3 align-items-center"><FontAwesomeIcon icon={faChessBoard} fixedWidth={true} style={{ color: "#1cb698", fontSize: "1.5rem" }} className="me-1" /><Trans>Chess</Trans></div>
                                    <div className="distinction d-inline-flex mb-3 align-items-center"><FontAwesomeIcon icon={faDiceD6} fixedWidth={true} style={{ color: "#1cb698", fontSize: "1.5rem" }} className="me-1" /><Trans>Board games</Trans></div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </div>);
    }
}

export default AboutMe;