import React from "react";
import classnames from "classnames";
import "./sectionHeader.scss";
import { Link as i18Link, Trans, } from 'gatsby-plugin-react-i18next';

export class SectionHeader extends React.Component<any, any> {
    render() {
        return (<div {...this.props} className={classnames("sectionHeader", this.props.side)}>
            <div className="title"><Trans>{this.props.title}</Trans></div>
            <div className="titleText"><Trans>{this.props.subtitle}</Trans></div>
        </div>)
    }
}

export default SectionHeader