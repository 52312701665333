import React from "react";
import { Link as i18Link, Trans, } from 'gatsby-plugin-react-i18next';
import { Link } from "react-scroll/modules";

import "./ResumeDownload.scss"

export class ResumeDownload extends React.Component {
    render() {
        return (<div className="resume-download">
            <Link activeClass="active" to="contact" spy={true} offset={-70} duration={200} smooth={'linear'} isDynamic={true}>  <button className="custom-btn btn-10"><Trans>CONTACT ME</Trans></button></Link>

        </div>)
    }
}

export default ResumeDownload;