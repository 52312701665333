import { faFacebookF, faInstagram, faLinkedinIn, faTwitter } from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { Link as i18Link, Trans, } from 'gatsby-plugin-react-i18next';

import "./SocialMedia.scss"

export class SocialMedia extends React.Component {
    render() {
        return (<div className="social-media-section">
            <div><h2 className="text-center"><Trans>Find me on social media</Trans></h2>
                <div className="socialMedia">
                    <a target="_blank" className="socialMediaLink" href="https://www.facebook.com/bartosz.lisiecki" title="Facebook">
                        <FontAwesomeIcon icon={faFacebookF} />
                    </a>
                    <a target="_blank" className="socialMediaLink" href="https://www.linkedin.com/in/bartosz-lisiecki/" title="LinkedIn">
                        <FontAwesomeIcon icon={faLinkedinIn} />
                    </a>
                    <a target="_blank" className="socialMediaLink" href="https://twitter.com/BartoszLisiecki" title="Twitter">
                        <FontAwesomeIcon icon={faTwitter} />
                    </a>
                    <a target="_blank" className="socialMediaLink" href="https://www.instagram.com/bartosz_lisiecki/" title="Instagram">
                        <FontAwesomeIcon icon={faInstagram} />
                    </a>
                </div>
            </div>

        </div>)
    }
}

export default SocialMedia;