import { faRaspberryPi } from "@fortawesome/free-brands-svg-icons";
import { faChevronCircleDown, faChevronDown, faChevronUp, faCode, faDatabase, faMinus, faMobileAlt, faNetworkWired, faPlus, faTv } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import SectionHeader from "../../sectionHeader";
import { Link as i18Link, Trans, } from 'gatsby-plugin-react-i18next';
import angular from "../../../images/technologies/angular.svg";
import arduino from "../../../images/technologies/arduino.svg";
import bootstrap from "../../../images/technologies/bootstrap.svg";
import csharp from "../../../images/technologies/csharp.svg";
import css3 from "../../../images/technologies/css3.svg";
import dotnetcore from "../../../images/technologies/dotnet-core.svg";
import expressjs from "../../../images/technologies/express-js.svg";
import html5 from "../../../images/technologies/html5.svg";
import javascript from "../../../images/technologies/javascript.svg";
import mongodb from "../../../images/technologies/mongodb.svg";
import mssql from "../../../images/technologies/mssql.svg";
import mysql from "../../../images/technologies/mysql.svg";
import nodejs from "../../../images/technologies/nodejs.svg";
import posgresql from "../../../images/technologies/postgresql.svg";
import raspberrypi from "../../../images/technologies/raspberry-pi.svg";
import react from "../../../images/technologies/react.svg";
import sass from "../../../images/technologies/sass.svg";
import socketio from "../../../images/technologies/socket-io.svg";
import typescript from "../../../images/technologies/typescript.svg";
import vue from "../../../images/technologies/vue.svg";
import webpack from "../../../images/technologies/webpack.svg";
import gatsby from "../../../images/technologies/gatsby.svg";
import materialui from "../../../images/technologies/materialui.svg";

import "./Services.scss"
import { Tooltip } from "bootstrap";

export class Services extends React.Component {

    componentDidMount() {
        // var tooltipTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="tooltip"]'))
        // tooltipTriggerList.map((tooltipTriggerEl) => {
        //     return new Tooltip(tooltipTriggerEl)
        // })
    }

    render() {
        return (
            <div className="section services" id="services" data-aos={"fade-up"}>
                <div className="sectionContainer container-lg">
                    <SectionHeader title="Services" side="left" />
                    <div className="sectionContent" >
                        <div className="row">
                            <div className="col-md-4 col-6 col-lg-2 p-0">
                                <div className="serviceBox box serviceBox--webdevelopment">
                                    <div className="serviceBox--icon">
                                        <FontAwesomeIcon icon={faCode} />
                                    </div>
                                    <div className="serviceBox--header"><Trans>Web apps</Trans></div>
                                    <div className="serviceBox--content"><Trans>Dynamic Internet applications using the latest standards and having the highest level of security.</Trans></div>
                                    {/* <div className="serviceBox--background">
                                       
                                    </div> */}
                                </div>
                            </div>
                            <div className="col-md-4 col-6 col-lg-2 p-0">
                                <div className="serviceBox box serviceBox--mobile">
                                    <div className="serviceBox--icon">
                                        <FontAwesomeIcon icon={faMobileAlt} />
                                    </div>
                                    <div className="serviceBox--header"><Trans>Mobile</Trans></div>
                                    <div className="serviceBox--content"><Trans>Intuitive, interactive and integrated mobile applications for you and your clients.</Trans></div>
                                    {/* <div className="serviceBox--background">
                                        <FontAwesomeIcon icon={faMobileAlt} />
                                    </div> */}
                                </div>
                            </div>
                            <div className="col-md-4 col-6 col-lg-2 p-0">
                                <div className="serviceBox box serviceBox--raspberrypi">
                                    <div className="serviceBox--icon">
                                        <FontAwesomeIcon icon={faRaspberryPi} />
                                    </div>
                                    <div className="serviceBox--header"><Trans>Embedded</Trans></div>
                                    <div className="serviceBox--content"><Trans>Embedded software for micro-computers and micro-controllers.</Trans></div>
                                    {/* <div className="serviceBox--background">
                                        <FontAwesomeIcon icon={faRaspberryPi} />
                                    </div> */}
                                </div>
                            </div>
                            <div className="col-md-4 col-6 col-lg-2 p-0">
                                <div className="serviceBox box serviceBox--database">
                                    <div className="serviceBox--icon">
                                        <FontAwesomeIcon icon={faDatabase} />
                                    </div>
                                    <div className="serviceBox--header"><Trans>Databases</Trans></div>
                                    <div className="serviceBox--content"><Trans>Thanks to my experience in designing and developing databases (and their migration), I will help you gain an advantage over the competition.</Trans></div>
                                    {/* <div className="serviceBox--background">
                                        <FontAwesomeIcon icon={faDatabase} />
                                    </div> */}
                                </div>
                            </div>
                            <div className="col-md-4 col-6 col-lg-2 p-0">
                                <div className="serviceBox box serviceBox--api">
                                    <div className="serviceBox--icon">
                                        <FontAwesomeIcon icon={faNetworkWired} />
                                    </div>
                                    <div className="serviceBox--header"><Trans>API</Trans></div>
                                    <div className="serviceBox--content"><Trans>I specialize in creating APIs (enterprise-grade REST) that are easy to use, well documented, and really reliable.</Trans></div>
                                    {/* <div className="serviceBox--background">
                                        <FontAwesomeIcon icon={faNetworkWired} />
                                    </div> */}
                                </div>
                            </div>

                            <div className="col-md-4 col-6 col-lg-2 p-0">
                                <div className="serviceBox box serviceBox--digitalsignage">
                                    <div className="serviceBox--icon">
                                        <FontAwesomeIcon icon={faTv} />
                                    </div>
                                    <div className="serviceBox--header"><Trans>Digital Signage</Trans></div>
                                    <div className="serviceBox--content"><Trans>Modern, interactive multimedia solutions.</Trans></div>
                                    {/* <div className="serviceBox--background">
                                        <FontAwesomeIcon icon={faTv} />
                                    </div> */}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="sectionContent technologies">
                        <div className="row">
                            <div className="col-md-4 mb-4">
                                <div style={{ color: "#1cb698" }}><Trans>TECHNOLOGY</Trans></div>
                                <h2 className="mb-4" style={{ fontWeight: "bold" }}><Trans>TOOLS I USE</Trans></h2>
                                {/* <div>
                                    Zakres technologi z których korzystam do osiągnięcia celów
                                </div> */}
                            </div>
                            <div className="col-md-8">
                                <div className="card">
                                    <div className="card-body p-0" >
                                        <a role="button" className="card-header" data-bs-toggle="collapse" href="#frontend">
                                            <h5 className="card-title m-0"><Trans>Frontend</Trans></h5>
                                            <FontAwesomeIcon icon={faChevronDown} />
                                            <FontAwesomeIcon icon={faChevronUp} />
                                        </a>
                                        <div className="collapse card-text show" id="frontend">
                                            <div className="card-body">
                                                <img className="image" src={react} alt="React JS" data-bs-toggle="tooltip" data-bs-placement="top" title="React JS" />
                                                <img className="image" src={angular} alt="Angular JS" data-bs-toggle="tooltip" data-bs-placement="top" title="Angular JS" />
                                                <img className="image" src={gatsby} alt="Gatsby" data-bs-toggle="tooltip" data-bs-placement="top" title="Gatsby" />
                                                <img className="image" src={typescript} alt="Typescript" data-bs-toggle="tooltip" data-bs-placement="top" title="Typescript" />
                                                <img className="image" src={materialui} alt="Material UI" data-bs-toggle="tooltip" data-bs-placement="top" title="Material UI" />
                                                <img className="image" src={bootstrap} alt="Bootstrap" data-bs-toggle="tooltip" data-bs-placement="top" title="Bootstrap" />
                                                <img className="image" src={webpack} alt="Webpack" data-bs-toggle="tooltip" data-bs-placement="top" title="Webpack" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="card">
                                    <div className="card-body p-0" >
                                        <a role="button" className="card-header collapsed" data-bs-toggle="collapse" href="#backend">
                                            <h5 className="card-title m-0"><Trans>Backend</Trans></h5>
                                            <FontAwesomeIcon icon={faChevronDown} />
                                            <FontAwesomeIcon icon={faChevronUp} />
                                        </a>
                                        <div className="collapse card-text" id="backend">
                                            <div className="card-body">
                                                <img className="image" src={dotnetcore} data-bs-toggle="tooltip" data-bs-placement="top" title=".Net Core" />
                                                <img className="image" src={nodejs} data-bs-toggle="tooltip" data-bs-placement="top" title="Node JS" />
                                                <img className="image" src={expressjs} data-bs-toggle="tooltip" data-bs-placement="top" title="Express JS" />
                                                <img className="image" src={socketio} data-bs-toggle="tooltip" data-bs-placement="top" title="Socket.io" />
                                                <img className="image" src={typescript} data-bs-toggle="tooltip" data-bs-placement="top" title="Typescipt" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="card">
                                    <div className="card-body p-0" >
                                        <a role="button" className="card-header collapsed" data-bs-toggle="collapse" href="#databases">
                                            <h5 className="card-title m-0"><Trans>Databases</Trans></h5>
                                            <FontAwesomeIcon icon={faChevronDown} />
                                            <FontAwesomeIcon icon={faChevronUp} />
                                        </a>
                                        <div className="collapse card-text" id="databases">
                                            <div className="card-body">
                                                <img className="image" src={mssql} data-bs-toggle="tooltip" data-bs-placement="top" title="Microsoft SQL Server" />
                                                <img className="image" src={mysql} data-bs-toggle="tooltip" data-bs-placement="top" title="MySQL" />
                                                <img className="image" src={posgresql} data-bs-toggle="tooltip" data-bs-placement="top" title="PostgreSQL" />
                                                <img className="image" src={mongodb} data-bs-toggle="tooltip" data-bs-placement="top" title="Mongo DB" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="card">
                                    <div className="card-body p-0" >
                                        <a role="button" className="card-header collapsed" data-bs-toggle="collapse" href="#iot">
                                            <h5 className="card-title m-0"><Trans>Internet of Things</Trans></h5>
                                            <FontAwesomeIcon icon={faChevronDown} />
                                            <FontAwesomeIcon icon={faChevronUp} />
                                        </a>
                                        <div className="collapse card-text" id="iot">
                                            <div className="card-body">
                                                <img className="image" src={raspberrypi} data-bs-toggle="tooltip" data-bs-placement="top" title="Raspberry PI" />
                                                <img className="image" src={arduino} data-bs-toggle="tooltip" data-bs-placement="top" title="Arduino" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default Services;