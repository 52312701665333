import { faBars } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import classnames from "classnames";
import { Link, Element, Events, animateScroll as scroll, scrollSpy, scroller } from 'react-scroll'
import { Link as i18Link, Trans, useI18next, } from 'gatsby-plugin-react-i18next';

import React from "react";

import "./header.scss";

class Header extends React.Component<any, any> {
    constructor(props) {
        super(props);

        this.state = {
            showMenu: false,
            scrolled: false
        }
    }

    toggleMenu = (state?: boolean) => {
        if (state == null) {
            this.setState({ showMenu: !this.state.showMenu });
        } else {
            this.setState({ showMenu: state });
        }
    }

    componentDidMount() {
        window.addEventListener('scroll', this.handleScroll);
        this.handleScroll();
    }

    componentWillUnmount() {
        window.removeEventListener('scroll', this.handleScroll);
    }

    handleScroll = () => {
        if (window.pageYOffset > 300) {
            this.setState({ scrolled: true })
        } else {
            this.setState({ scrolled: false })
        }
    }

    render() {
        return (<div className={classnames("header", { "scrolled": this.state.scrolled || this.state.showMenu, })}>
            <div className="headerContainer container-lg">
                <div className="logo"><span>BARTOSZ LISIECKI</span></div>
                <div className="menuContainer">
                    <div className={classnames("menu", { "show": this.state.showMenu })}>
                        <div>
                            <ul>
                                <li className="menuItem"><Link onClick={() => this.toggleMenu(false)} activeClass="active" to="start" spy={true} duration={200} smooth={'linear'} isDynamic={true}>START</Link></li>
                                <li className="menuItem"><Link onClick={() => this.toggleMenu(false)} activeClass="active" to="about" spy={true} offset={-70} duration={200} smooth={'linear'} isDynamic={true}><Trans>ABOUT</Trans></Link></li>
                                {/* <li className="menuItem"><Link onClick={() => this.toggleMenu(false)} activeClass="active" to="resume" spy={true} offset={-70} duration={200} smooth={'linear'} isDynamic={true}>RESUME</Link></li> */}
                                <li className="menuItem"><Link onClick={() => this.toggleMenu(false)} activeClass="active" to="services" spy={true} offset={-70} duration={200} smooth={'linear'} isDynamic={true}><Trans>SERVICES</Trans></Link></li>
                                <li className="menuItem"><Link onClick={() => this.toggleMenu(false)} activeClass="active" to="contact" spy={true} offset={-70} duration={200} smooth={'linear'} isDynamic={true}><Trans>CONTACT</Trans></Link></li>
                            </ul>
                            <div className="d-lg-none menuBackground" onClick={() => this.toggleMenu(false)}></div>
                        </div>
                    </div>
                    <div className="d-lg-none">
                        <a onClick={() => this.toggleMenu()} className="menuIcon">
                            <div className={classnames("hamburger hamburger--squeeze", { "is-active": this.state.showMenu })}>
                                <div className="hamburger-box">
                                    <div className="hamburger-inner"></div>
                                </div>
                            </div>
                        </a>
                    </div>

                </div>
            </div>
        </div>)
    }
}

export default Header;